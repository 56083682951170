<template>
  <div class="f2g-text-field" :class="componentClass">
    <v-text-field
      class="mt-0 pt-0"
      :class="disabled ? `text-field` : ''"
      :color="color"
      :clearable="clearable"
      :disabled="disabled"
      :error-messages="errorMessages"
      :full-width="fullWidth"
      :id="fieldId"
      :label="label"
      :maxLength="maxLength"
      :counter="counter"
      :prepend-inner-icon="prependInnerIcon"
      :required="required"
      :hint="hint"
      :persistent-hint="persistentHint"
      :type="type"
      :suffix="suffix"
      :readonly="readonly"
      v-model="vModelToShow"
      v-on:input="inputField"
      v-on:keypress.,="commaPressedEvent"
      @input="inputField"
      @blur="touchField"
      @keyup.enter="enterPressed"
      @click:clear="$emit('cleared')"
      @click="$emit('clicked'), (showHelpCard = false)"
    >
      <template #label>
        {{ label }}<RedAsterisk v-if="required && !disabled" />
      </template>
      <template #prepend>
        <slot name="prepended"> </slot>
      </template>
      <template #prepend-inner>
        <slot name="prepend-inner"> </slot>
      </template>
      <template #append>
        <slot name="append"> </slot>
        <F2gIcon
          v-if="hasHelpTip && checkHelpTextSettings"
          class="mr-3 icon"
          :height="17"
          :width="17"
          value="question-mark"
          @click.stop="showHelpCard = !showHelpCard"
        />
        <transition name="fade">
          <F2gCard
            v-if="showHelpCard"
            class="helpCard"
            :style="
              helpCardFixedWidth
                ? `width: ${helpCardFixedWidth}px;${setStyle()}`
                : `width: 100%;${setStyle()}`
            "
            ><div class="pa-3">
              <span class="mt-1 message" v-html="helpMessage"></span>
            </div>
          </F2gCard>
        </transition>
      </template>
      <template #append-outer>
        <slot name="append-outer"> </slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "F2g-text-field",
  components: {
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
  },
  props: {
    color: { type: String, required: false, default: "primary" },
    counter: { type: [String, Number], required: false, default: null },
    clearable: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    errorMessages: { type: [], required: false, default: "" },
    fullWidth: { type: Boolean, required: false, default: false },
    fieldId: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    maxLength: { type: String, required: false, default: null },
    prependInnerIcon: { type: String, required: false, default: "" },
    readonly: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    persistentHint: { type: Boolean, required: false, default: false },
    showPercentage: { type: Boolean, required: false, default: false },
    hint: { type: String, required: false, default: null },
    type: { type: String, required: false, default: "text" },
    value: { type: [String, Number], required: false, default: "" },
    componentClass: { type: String, required: false, default: "" },
    hasHelpTip: { type: Boolean, required: false, default: false },
    isForm: { type: Boolean, required: false, default: false },
    helpCardFixedWidth: { type: Number, required: false, default: null },
    helpMessage: { type: String, required: false, default: "" },
    helpCardWidth: { type: Number, required: false, default: 200 },
    helpCardMaxWidth: { type: Number, required: false, default: 200 },
    helpCardTopPixels: { type: Number, required: false, default: 0 },
    helpCardLeftPixels: { type: Number, required: false, default: 0 },
    suffix: { type: String, required: false },
    commaPressed: { type: Boolean, required: false },
  },
  data: () => ({
    vModelToShow: {},
    showHelpCard: false,
  }),
  computed: {
    ...mapGetters({
      userProfile: "auth/getUserProfile",
    }),
    checkHelpTextSettings() {
      let showHelp = false;
      if (
        (this.userProfile.settings.showDetailHelpMessages &&
          this.userProfile.settings.showFormHelpMessages) ||
        (this.userProfile.settings.showFormHelpMessages && this.isForm)
      ) {
        showHelp = true;
      }
      return showHelp;
    },
  },
  watch: {
    value() {
      this.vModelToShow = this.value;
    },
    showHelpCard() {
      if (this.showHelpCard) {
        window.addEventListener(
          "click",
          () => (this.showHelpCard = false),
          null
        );
      } else {
        window.removeEventListener("click", null, null);
      }
    },
  },
  mounted() {
    this.vModelToShow = this.value;
  },
  methods: {
    inputField() {
      this.$emit("input", this.vModelToShow);
    },
    enterPressed() {
      this.$emit("enterPressed");
    },
    commaPressedEvent() {
      if (this.commaPressed) {
        this.$emit("commaPressedEvent");
      }
    },
    touchField() {
      if (this.required) {
        this.$emit("touch");
      }
    },
    setStyle() {
      return `top: ${this.helpCardTopPixels}px; left: ${this.helpCardLeftPixels}px;`;
    },
  },
};
</script>
<style scoped lang="scss">
.f2g-text-field {
  position: relative;
  .helpCard {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.096) !important;
    position: absolute;
    z-index: 1000 !important;
    text-align: left;
    text-justify: inter-word;
    line-height: 1.2;
  }
  .icon {
    cursor: pointer;
  }
  .text-field {
    pointer-events: auto;
  }
  .message {
    font-size: 13px;
  }
}
</style>
